















































import {forEach} from '@labor-digital/helferlein/lib/Lists/forEach';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {VuDropdownItem, VuDropdownTrigger} from '@labor-digital/vuetiful/src/index';
import Dropdown from '../../Misc/Dropdown/Dropdown.vue';
import ImageTag from '../../Misc/Image/ImageTag/ImageTag.vue';
import LinkWrap from '../../Misc/Link/LinkWrap/LinkWrap.vue';

export default {
    name: 'LanguageSwitch',
    components: {ImageTag, LinkWrap, Dropdown, VuDropdownItem, VuDropdownTrigger},
    props: {
        context: null as PageContext
    },
    data()
    {
        return {open: false};
    },
    computed: {
        entries()
        {
            return (this.context as PageContext).commonElements.languageSwitcher;
        },
        currentEntry()
        {
            let result = {};
            const currentId = (this.context as PageContext).translation.languageCode;
            forEach(this.entries, entry => {
                result = entry;
                if (currentId === entry.id) {
                    return false;
                }
            });
            return result;
        }
    }
};
