var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "languageSwitch" },
    [
      _c(
        "Dropdown",
        {
          attrs: { "with-arrow": "" },
          scopedSlots: _vm._u([
            {
              key: "trigger",
              fn: function () {
                return [
                  _c(
                    "VuDropdownTrigger",
                    { staticClass: "languageSwitch__button" },
                    [
                      _c("img", {
                        staticClass:
                          "languageSwitch__buttonIcon languageSwitch__languageIcon",
                        attrs: {
                          src: "/assets/flags/" + _vm.currentEntry.id + ".svg",
                          width: "24px",
                          height: "24px",
                          alt: _vm.currentEntry.navigationTitle,
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("navigation.languageSwitch.label"))
                        ),
                      ]),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.open,
            callback: function ($$v) {
              _vm.open = $$v
            },
            expression: "open",
          },
        },
        [
          _vm._v(" "),
          _vm._l(_vm.entries, function (entry) {
            return entry.id !== _vm.currentEntry.id
              ? _c(
                  "VuDropdownItem",
                  {
                    key: entry.id,
                    attrs: {
                      lang: entry.hreflang,
                      hreflang: entry.hreflang,
                      link: entry.link,
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "languageSwitch__languageIcon",
                      attrs: {
                        src: "/assets/flags/" + entry.id + ".svg",
                        width: "24px",
                        height: "24px",
                        alt: entry.navigationTitle,
                      },
                    }),
                    _vm._v(
                      "\n            " +
                        _vm._s(entry.navigationTitle) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e()
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }